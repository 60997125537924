<template>
  <div class="history-or">
    <SearchBarOr />
    <div class="container-or">
      <RingLoading v-if="searchLoading" />
      <div ref="oreventcard" class="event-card" v-for="(event, index) in orEventList" :key="event.id">
        <OrEventCard :event="event" :index="index" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  name: 'HistoryOr',
  components: {
    SearchBarOr: () => import('@/components/History/SearchBarOr.vue'),
    OrEventCard: () => import('@/components/History/HistoryContent/EventBoard/OrEventCard.vue'),
    RingLoading: () => import('@/components/Base/RingLoading.vue'),
  },
  data() {
    return {
      fullPage: false,
    }
  },
  computed: {
    ...mapState('historyOr', ['orEventList', 'searchLoading', 'orFocusIndex']),
  },
  watch: {
    orFocusIndex() {
      this.$nextTick(() => {
        const el = this.$refs.oreventcard[this.orFocusIndex]
        if (el) {
          el.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
      })
    },
  },
  mounted() {
    // this.getFrTagList()
    this.updateHistoryEventModalType('or')
  },
  beforeDestroy() {
    this.resetHistoryOrData()
  },
  methods: {
    ...mapMutations('history', ['updateHistoryEventModalType']),
    // ...mapActions(['getFrTagList']),
    ...mapActions('historyOr', ['resetHistoryOrData']),
    onCancel() {},
  }
}
</script>

<style lang="scss" scoped>
.history-or {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;

  .container-or {
    flex: 1;
    width: 100%;
    height: 100%;
    padding: 10px 20px;
    overflow-y: overlay;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    box-sizing: border-box;
    background: #282942;
    position: relative;
  }
}
</style>